//setTimeout(function() {
    //window.location = "https://yourdomain.com";
  //  alert("any insta  on ios")
  //}, 25);
  
  // If "yourapp://" is registered, the user will see a dialog
  // asking if they want to open your app. If they agree, your
  // app will launch immediately and the timer won't fire.
  // If not installed, you'll get an ugly "Cannot Open Page" 
  // dialogue and your fallback page will open when the timer expires.
  
  //window.location = "instagram://oye.agency/";

  //setTimeout(function () { window.location = "https://hello-default-oyestudio.dev.8thwall.app/hole-stars-epfl/"; }, 25);
  
  //window.location = "instagram://user?username=oye.agency";

  let popupOpened = false
  
  let btnClosePopup = document.querySelector('#close__btn')
  let btnOPenPopup = document.querySelector('#help__btn')
  let popupContainer = document.querySelector('#popup')

  btnOPenPopup.addEventListener('click', () => {
      popupOpened = !popupOpened

      if(popupOpened){
        // open popup
        openPopup()
      }else{
        // close popup
        closePopup()
      }

  })

  btnClosePopup.addEventListener('click', () => {
      
    popupOpened = false
    closePopup()

})

  const closePopup = () => {
    popupContainer.classList = "hidden"    

  }

  const openPopup = () => {
    popupContainer.classList = "show"   
  }